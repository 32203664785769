<template>
  <div class="container">
    <ul class="wrapper">
      <li class="icon login" @click="$router.replace('/login')">
        {{ $t('home.left.signIn') }}
        <span class="tooltip"> {{ $t('home.left.signIn') }}</span>
        <span><i></i></span>
      </li>
      <li class="icon register" @click="$router.replace('/register')">
        {{ $t('home.left.signUp') }}
        <span class="tooltip"> {{ $t('home.left.signUp') }}</span>
        <span><i></i></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RightBottom"
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: inline-flex;
  list-style: none;
  justify-content: center;
}

.wrapper .icon {
  position: relative;
  border-radius: 10%;
  padding: 15px;
  margin: 10px;
  width: 68px;
  height: 38px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  color: #225ffd;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .login:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
}

.wrapper .register:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #fa9546;
}
</style>

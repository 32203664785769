<template>
  <div class="left">
    <div class="menu-system" @touchmove.prevent>
      <!--   公告    -->
      <div style="
      box-sizing: border-box;
      flex: 1;
      max-height: 100%;">
        <div style="padding: 3vw;
      margin:3vw;
      border-radius: 1vw;
      background:#73BBC9;
      ">
          <h1 style="font-size: 15px;margin-bottom: 3px">{{ $t('home.left.desc') }}</h1>
        </div>
      </div>
      <div v-if="hasUserInfoAndToken" class="profile-box">
        <div class="profile menu-link" @click="$router.push('/profile');">
          <div class="icon-wrapper">
            <svg t="1685778430548" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="7398" width="200" height="200">
              <path
                  d="M959.36 920.96C944 739.2 819.84 588.8 651.52 534.4c70.4-45.44 117.12-124.16 117.12-214.4 0-141.44-115.2-256-256.64-256-142.08 0-256.64 114.56-256.64 256 0 90.24 46.72 168.96 117.12 214.4C204.16 588.8 80 739.2 64.64 920.96c0 3.2-0.64 5.76 0 10.24C65.92 947.2 80 960 96 960c17.92 0 32-14.72 32-32 16.64-197.76 182.4-352 384-352s366.72 154.24 384 352c0 17.28 14.08 32 32 32 16 0 30.08-12.8 31.36-28.8 0.64-4.48 0-7.04 0-10.24zM319.36 320c0-106.24 86.4-192 192.64-192 106.24 0 192.64 85.76 192.64 192S618.24 512 512 512c-106.24 0-192.64-85.76-192.64-192z"
                  p-id="7399"></path>
            </svg>
            <span>{{ $t('home.left.profile') }}</span>
          </div>
        </div>

        <div class="logout menu-link" @click="logout">
          <div class="icon-wrapper">
            <svg t="1685778577682" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="9335" width="200" height="200">
              <path
                  d="M512 64a32 32 0 0 0-32 32v416a32 32 0 0 0 64 0V96a32 32 0 0 0-32-32z m171.328 33.952a32 32 0 0 0-24.704 59.072l0.224 0.064C798.048 214.752 896 351.936 896 512c0 212.096-171.904 384-384 384S128 724.096 128 512c0-160.064 97.92-297.248 237.152-354.912a32 32 0 1 0-24.672-59.04l0.224-0.064c-162.4 67.264-276.672 227.328-276.672 414.048 0 247.424 200.576 448 448 448s448-200.576 448-448c0-186.752-114.24-346.784-276.672-414.048z"
                  p-id="9336"></path>
            </svg>
            <span>{{ $t('home.left.logout') }}</span>
          </div>
        </div>

        <div class="upload menu-link" @click="$router.push('/admin/upload');">
          <div class="icon-wrapper">
            <svg t="1693142750408" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="4289" width="200" height="200">
              <path
                  d="M832.802797 285.953527c-10.94962-65.066009-43.166769-124.446638-92.440058-169.508534-54.748098-50.115567-125.920625-77.700185-200.041127-77.700185-57.274933 0-113.075879 16.424429-160.87518 47.378161-39.797656 25.689492-72.857084 60.433477-96.23031 101.283981-10.107341-1.895126-20.635822-2.947975-31.164302-2.947974-89.492083 0-162.349167 72.857084-162.349167 162.349167 0 11.581328 1.263418 22.741518 3.369113 33.691137C35.165124 422.613202 0 490.416615 0 562.641991c0 58.327781 21.68867 114.971006 61.275756 159.822331 40.639934 45.904174 94.335184 73.067654 151.610117 76.226198h182.774419c15.792721 0 28.426897-12.634176 28.426897-28.426897s-12.634176-28.426897-28.426897-28.426897h-180.247584C129.289739 736.572486 56.853794 654.660909 56.853794 562.431421c0-59.591199 32.00658-115.181575 83.596134-145.293029 12.002468-6.948797 17.056138-21.4781 12.423607-34.533416-4.211392-11.370759-6.317088-23.373226-6.317088-36.217972 0-58.117212 47.378162-105.495373 105.495373-105.495373 12.423607 0 24.636644 2.105696 36.007403 6.317088 13.897594 5.053671 29.269175-1.263418 35.586263-14.529303 39.376517-83.596134 124.446638-137.501954 216.886696-137.501954 124.236068 0 226.783467 93.071766 238.575365 216.465557 1.263418 12.844746 10.94962 23.162657 23.583796 25.268353 93.703475 16.00329 164.454863 102.547399 164.454863 201.304544 0 104.653095-82.332716 195.619165-183.827267 203.410241h-155.189801c-15.792721 0-28.426897 12.634176-28.426897 28.426897s12.634176 28.426897 28.426897 28.426897h158.348345c64.22373-4.632531 124.236068-34.112276 168.876825-83.385565 44.430187-48.852149 68.645692-111.601892 68.645692-176.87847-0.21057-118.12955-80.858729-223.203784-191.197203-252.262389z"
                  p-id="4290" fill="#000000"></path>
              <path
                  d="M682.666667 589.5949c11.160189-11.160189 11.160189-29.058606 0-40.218795l-150.55727-150.557269c-5.26424-5.26424-12.634176-8.422784-20.004112-8.422784s-14.739873 2.947975-20.004113 8.422784l-150.557269 150.557269c-11.160189 11.160189-11.160189 29.058606 0 40.218795 5.47481 5.47481 12.844746 8.422784 20.004113 8.422785s14.529303-2.737405 20.004112-8.422785l102.12626-102.126259v469.359654c0 15.792721 12.634176 28.426897 28.426897 28.426897s28.426897-12.634176 28.426897-28.426897V487.468641l102.126259 102.126259c10.94962 11.160189 28.848036 11.160189 40.008226 0z"
                  p-id="4291" fill="#000000"></path>
            </svg>
            <span>{{ $t('home.left.upload') }}</span>
          </div>
        </div>
      </div>
      <div class="right-bottom" v-else>
        <RightBottom></RightBottom>
      </div>
      <!--   Settings    -->
      <div class="appearance" style="justify-content: space-between;" @click="toggleSettings">
        <div style="  display: flex;">
          <svg t="1690376737476" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
               p-id="1460" width="30" height="30">
            <path
                d="M416.4 958h191.2V849.7c0-12.7 6.4-25.5 19.1-31.9 31.9-12.7 63.7-31.9 89.2-51 12.7-6.4 25.5-6.4 38.2 0l95.6 57.3 95.6-165.7-95.6-57.3C837 588.5 830.6 575.7 837 563c0-19.1 6.4-31.9 6.4-51s0-31.9-6.4-51c0-12.7 6.4-25.5 12.7-31.9l95.6-57.3-95.6-165.7-95.6 57.3c-12.7 6.4-25.5 6.4-38.2 0-25.5-19.1-57.3-38.2-89.2-51-12.7-12.7-19.1-25.5-19.1-38.2V66H416.4v108.3c0 12.7-6.4 25.5-19.1 31.9-31.9 12.7-63.7 31.9-89.2 51-12.7 6.4-25.5 6.4-38.2 0l-95.6-51-95.6 165.6 95.6 57.3c12.7 6.4 19.1 19.1 12.7 31.9 0 19.1-6.4 31.9-6.4 51s0 31.9 6.4 51c6.4 12.7 0 25.5-12.7 31.9l-95.6 57.3 95.6 165.7 95.6-57.3c12.7-6.4 25.5-6.4 38.2 0 25.5 19.1 57.3 38.2 89.2 51 12.7 6.4 19.1 19.1 19.1 31.9V958z m223 63.7H384.6c-19.1 0-31.9-12.7-31.9-31.9v-121c-25.5-12.7-51-25.5-70.1-38.2l-101.9 63.7c-12.7 6.4-31.9 6.4-44.6-12.7L8.6 658.6c-12.7-19.1-6.4-38.2 12.7-44.6l101.9-63.7v-76.5L21.4 410.1c-19.1-6.4-25.5-25.5-12.7-44.6l127.4-223c6.4-12.7 25.5-19.1 44.6-6.4l101.9 63.7c19.1-12.7 44.6-31.9 70.1-38.2V34.1c0-19.1 12.7-31.9 31.9-31.9h254.9c19.1 0 31.9 12.7 31.9 31.9v121.1c25.5 12.7 51 25.5 70.1 38.2l101.9-63.7c12.7-6.4 31.9-6.4 44.6 12.7l127.4 223c12.7 19.1 6.4 38.2-12.7 44.6l-101.9 63.7v76.5l101.9 63.7c12.7 6.4 19.1 25.5 12.7 44.6L888 881.5c-6.4 12.7-25.5 19.1-44.6 12.7l-101.9-63.7c-19.1 12.7-44.6 31.9-70.1 38.2v121.1c-0.1 19.2-12.8 31.9-32 31.9zM512 703.2c-108.3 0-191.2-82.8-191.2-191.2S403.7 320.8 512 320.8 703.2 403.7 703.2 512 620.3 703.2 512 703.2z m0-318.6c-70.1 0-127.4 57.3-127.4 127.4S441.9 639.4 512 639.4 639.4 582.1 639.4 512 582.1 384.6 512 384.6z"
                p-id="1461" fill="#000000"></path>
          </svg>
          <span>{{ $t('home.left.settings') }}</span>
        </div>
        <svg t="1690600428245" class="iconRotated" viewBox="0 0 1024 1024" version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             p-id="7382" width="200" height="200">
          <path
              d="M688.96 517.12a31.872 31.872 0 0 0-7.68-12.224L455.04 278.592a32 32 0 0 0-45.248 0l-22.592 22.656a32 32 0 0 0 0 45.248l186.752 186.816L391.04 716.16a32 32 0 0 0 0 45.248l22.656 22.656a32 32 0 0 0 45.248 0l226.24-226.304a32 32 0 0 0 3.776-40.768z"
              fill="#000000" p-id="7383"></path>
        </svg>
      </div>
      <transition name="slide">
        <div v-if="showSettings" style="padding: 0 0 0 6vw">
          <!--   Switch Mode    -->
          <SwitchMode></SwitchMode>
          <!-- 按钮用于切换语言 -->
          <div class="appearance" @click="togglePopup">
            <svg t="1690301595676" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="7486" width="30" height="30">
              <path
                  d="M512.9 960.2c-245.5 0-445.3-199.8-445.3-445.3S267.4 69.6 512.9 69.6c97.2 0 189.6 30.8 267.1 89 15.1 11.4 18.2 32.8 6.8 48-11.4 15.1-32.8 18.2-48 6.8-65.6-49.2-143.7-75.2-226-75.2C305 138.2 136 307.2 136 515s169 376.8 376.8 376.8 376.8-169 376.8-376.8c0-48.5-9.1-95.8-27-140.4-7.1-17.6 1.5-37.5 19-44.6 17.6-7.1 37.5 1.5 44.6 19 21.2 52.8 32 108.6 32 165.9 0 245.6-199.7 445.3-445.3 445.3z"
                  fill="#000" p-id="7487"></path>
              <path d="M829.1 273.3m-39.5 0a39.5 39.5 0 1 0 79 0 39.5 39.5 0 1 0-79 0Z" fill="#2c2c2c"
                    p-id="7488"></path>
              <path
                  d="M523.6 954.4c-6.3 0-12.6-2.2-17.8-6.6-16.1-13.7-31.6-28.6-46.1-44.3-45.5-48.9-81.4-104.9-106.8-166.5-27.4-66.3-41.4-136.5-41.9-208.8v-3.5c0-61.6 10.1-122.2 29.8-180 28.8-84.1 78.2-161.2 143-222.8 4.9-4.7 10-9.3 15.1-13.8 11.3-10 28.7-9 38.7 2.4 10 11.3 9 28.7-2.4 38.7-4.6 4.1-9.2 8.3-13.6 12.5-58.3 55.6-102.9 125-128.9 200.8-17.8 52.1-26.9 106.7-26.9 162.3v3.3c0.4 65 13.1 128.3 37.7 188 22.9 55.5 55.3 106 96.3 150.1 13.1 14.2 27.1 27.6 41.5 39.9 11.5 9.8 12.9 27.1 3 38.6-5.2 6.4-12.9 9.7-20.7 9.7z"
                  fill="#000" p-id="7489"></path>
              <path
                  d="M487.4 953.1c-8.4 0-16.6-3.8-22-11-9.1-12.1-6.6-29.3 5.6-38.4 16-11.9 31.4-25 45.9-38.7 43.8-41.7 79.3-90.2 105.5-144.3 29.6-60.8 46.3-126.1 49.6-193.9 0.4-8.5 0.6-16.9 0.6-25 0-43.4-5.5-86.6-16.5-128.1-20.8-79.2-61.5-153-117.5-213.3-4-4.4-8.3-8.8-13.2-13.7-10.7-10.7-10.8-28-0.1-38.8 10.7-10.7 28-10.8 38.8-0.1 5.6 5.5 10.4 10.6 14.9 15.5 62 66.7 107.1 148.5 130.2 236.5 12.1 46.1 18.3 93.9 18.3 142.1 0 9-0.2 18.3-0.7 27.6-3.7 75.3-22.2 147.7-55.1 215.2-29.1 60-68.5 113.8-117 160-16 15.3-33.1 29.7-50.9 42.9-5 3.7-10.7 5.5-16.4 5.5z"
                  fill="#000" p-id="7490"></path>
              <path
                  d="M834.9 747.1c-5.3 0-10.7-1.5-15.4-4.8-58.1-39.7-122.1-67.9-190.2-83.9-42.6-10-86.6-15.1-130.8-15.1h-0.2c-33.2 0-66.3 2.8-98.6 8.5-73 12.7-142 39.3-204.9 78.9-12.8 8.1-29.7 4.2-37.8-8.6-8.1-12.8-4.2-29.7 8.6-37.8 69-43.4 144.6-72.5 224.7-86.5 35.4-6.2 71.7-9.3 108-9.3h0.2c48.4 0 96.6 5.6 143.3 16.6 74.7 17.5 144.9 48.5 208.6 92 12.5 8.5 15.7 25.6 7.2 38.1-5.4 7.7-14 11.9-22.7 11.9zM924 480.5l-801.8-0.3c-15.1 0-27.4-12.3-27.4-27.4 0-15.1 12.3-27.4 27.4-27.4l801.8 0.3c15.1 0 27.4 12.3 27.4 27.4 0 15.2-12.3 27.4-27.4 27.4z"
                  fill="#000" p-id="7491"></path>
            </svg>
            <span>{{ $t('home.left.language') }}</span>
            <div class="language-bg" v-if="showPopup" @click="hidePopup"></div>
            <div class="language-popup" v-if="showPopup">
              <span class="lang-button" @click="switchLanguage('zh')">中文</span>
              <span class="lang-button" @click="switchLanguage('en')">English</span>
            </div>

          </div>
          <!--   Telegram    -->
          <div class="appearance">
            <svg t="1690382169715" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="8582" width="30" height="30">
              <path
                  d="M417.28 795.733333l11.946667-180.48 327.68-295.253333c14.506667-13.226667-2.986667-19.626667-22.186667-8.106667L330.24 567.466667 155.306667 512c-37.546667-10.666667-37.973333-36.693333 8.533333-55.466667l681.386667-262.826666c31.146667-14.08 61.013333 7.68 49.066666 55.466666l-116.053333 546.56c-8.106667 38.826667-31.573333 48.213333-64 30.293334L537.6 695.466667l-84.906667 82.346666c-9.813333 9.813333-17.92 17.92-35.413333 17.92z"
                  fill="#000000" p-id="8583"></path>
            </svg>
            <span>
              <a href="https://t.me/+XxrdHjBU9qRhMWNl" target="_blank">{{ $t('home.left.tg') }}</a>
            </span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import RightBottom from "@/pages/home/components/HeaderBar/left/RightBottom";
import SwitchMode from "@/pages/home/components/HeaderBar/left/SwitchMode";

export default {
  name: 'HeaderLeft',
  data() {
    return {
      showPopup: false,
      showSettings: false,
      isRotated: false
    };
  },
  computed: {
    hasUserInfoAndToken() {
      return localStorage.getItem('aikan_user_info') && localStorage.getItem('aikan_user_token');
    }
  },
  components: {
    RightBottom,
    SwitchMode
  },
  methods: {
    logout() {
      // 从本地存储中删除 aikan_user_info 和 aikan_user_token
      localStorage.removeItem('aikan_user_info');
      localStorage.removeItem('aikan_user_token');
      // 刷新页面，跳转到主页面
      location.reload()
    },
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    toggleSettings() {
      // 获取 <path> 元素
      this.showSettings = !this.showSettings;
      const path = document.querySelector('.iconRotated path');
      // 判断是否需要旋转并设置旋转角度
      const rotateDeg = this.isRotated ? 0 : 90;
      // 设置旋转动画
      path.style.transition = 'transform 0.3s ease-in-out';
      // 应用旋转变换
      path.style.transform = `rotate(${rotateDeg}deg)`;
      // 更新旋转状态
      this.isRotated = !this.isRotated;
    },
    hidePopup() {
      this.showPopup = true;
    },
    switchLanguage(locale) {
      this.$i18n.locale = locale; // 切换语言
      // 将用户选择的语言设置存储在本地存储中
      localStorage.setItem('lang', locale);
      // 在语言切换方法中，重新加载整个页面
      window.location.reload();
    }
  }
}

</script>

<style>
/* 使用 Vue 的过渡类名来设置过渡效果 */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.2s; /* 设置过渡的动画时间 */
}

.slide-enter, .slide-leave-to /* .slide-leave-active 在 Vue 2.1.8+ 中 */
{
  transform: translateY(100%); /* 初始状态（移入）和结束状态（移出）的位置 */
}

.profile-box {
  padding: 20px 0;
}

.language-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明的灰色背景 */
}

.language-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  padding: 3vw;
  border-radius: 10px;
  z-index: 100;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.lang-button {
  display: block;
  cursor: pointer;
  padding: 5px;
}

/* 可根据需要添加更多样式 */

.appearance svg {
  height: 24px;
  width: 24px;
}

.appearance span {
  padding-left: 3vw;
}

.appearance svg path {
  fill: var(--icon-color);
}

.lang-button {
  margin: 33px 20px;
  padding: 21px 88px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.lang-button:hover {
  background-color: #0056b3;
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
}

.icon-wrapper span {
  margin-left: 10px;
}

.menu-link {
  cursor: pointer;
  display: flex;
  padding-left: 3vw;
  margin-top: 10px;
  align-items: center;
}

.menu-link > * {
  margin-right: 8px;
}

.logout svg, .profile svg, .upload svg {
  width: 24px;
  height: 24px;
  fill: var(--0f-color);
}

.left {
  height: 100%;
}

.menu-system {
  overflow: hidden;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--menu-bg-color);
  pointer-events: all;

}

.menu-system-list p {
  display: inline-block;
  text-indent: 0.2rem;
  color: #4e534e;
}

.menu-system-list span {
  color: #6cf;
}
</style>
